import React from 'react';
import "./simple-paragraph.scss"
import CustomMarkdown from "../../utils/customMarkdown"

const SimpleParagraph = ({ props }) => {
    return (
        <div className="paragraph-container">
            <h1 className="title">{props.title}</h1>
            {props.title ? <hr style={{ width: "50px", margin: "0px auto 30px auto" }} /> : ""}
            <div className="paragraph-info">
                {
                    props.text.map((item, index) => {
                        return (
                            <div>
                                <CustomMarkdown className="info">{item.info}</CustomMarkdown>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    );
};

export default SimpleParagraph;