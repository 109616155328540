import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { covid19 } from "../utils/generalInformation";

import SimpleParagraph from "../components/simple-paragraph/simple-paragraph";


// markup
const Covid19Statement = () => {


  return (
    <Layout
      SEOTitle={"Covid-19 Statement - United Legal Assistance"}
      SEODescription={"Covid-19 Statement - United Legal Assistance"}
      footerDisclaimer={""}>

      <SimpleParagraph props={covid19} />

    </Layout>
  )

}

export default Covid19Statement