import manchester from '../assets/manchester.jpg'
import everton from '../assets/everton.jpg'
import manchester_overlay from '../assets/manchester_overlay.png'
import everton_overlay from '../assets/everton_overlay.png'

import webpMan from '../assets/manUnt.webp'
import pngMan from '../assets/manUnt.png'
import webpEvr from '../assets/eftLanding.webp'
import pngEvr from '../assets/eftLanding.png'


//covid-19-statement page
export const covid19 = {
    title: "Covid-19 Statement",
    text: [
        {
            info: "Thursday 19th March 2020",
        },
        {
            info: "**United Legal Assistance Limited Statement regarding Covid19 Coronavirus**",
        },
        {
            info: "Adam, Gavin, Pete and all at United Legal Assistance would like to reassure all clients and partners that we are taking every step possible to be responsible during this incredibly unique and difficult situation.",
        },
        {
            info: "Subsequently, the above action is likely to lead to a delay in some tasks, due to slower systems/internet connections, however, we are keen to stress that the services we provide our clients and partners will continue to take place.",
        },
        {
            info: "In addition, all of our broker partner managers have been instructed to cancel all non-essential travel and meetings. They are all working from home, so will be available to assist in any way they can, via telephone, email or Skype.",
        },
        {
            info: "As a result of this pandemic, some Insurers are advising that they have limited staff and that their service may vary. They have also informed us that they will not respond to constant calls or emails, and anyone found guilty of doing so will be put to the back of the queue.",
        },
        {
            info: "Consequently, we ask for your patience during this period, as we may not be able to perform to our usually high standards for reasons out of our control.",
        },
        {
            info: "We are in constant communication with our partners; hire companies, repairers and insurance brokers, to ensure that we are able to perform the roles and responsibilities asked of us and should anything change we will update everyone further.",
        },
        {
            info: "If we can assist anyone further, whether it’s by taking Fault claims, redirecting your claims number to us or other types of claims, please get in touch and we will do all we can to work together to get the best outcome for our clients.",
        },
        {
            info: "Finally, we would like to ask everyone to remain diligent, follow the Government guidelines in trying to reduce the spread and think of others when buying your supplies.",
        },
        {
            info: "We are all in this together.",
        },
    ]
}


//hospitality page
export const hospitality = {
    title: 'Hospitality',
    text: [
        {
            info: '**We understand the need to build strong relationships in order to create a strong, long-lasting, working partnership – what better way to start that journey, than to enjoy a fantastic meal, or drink, or both!**'
        },
        {
            info: '**We have a table in the Gallery Lounge at Old Trafford for all Manchester United FC home games, as well as a table in the Brian Labone Lounge at Goodison Park for Everton FC. In recent times we have held events at The Open Golf Championship and the British Grand Prix, the Grand National at Aintree Racecourse, as well as a golf day at the prestigious Royal Birkdale Golf Club.**'
        },
        {
            info: '**We know, through experience, that all of this not only builds relationships, but enhances them – and as such, we give our broker partners access to bring existing/prospective clients, ensuring they are aware of the partnership we hold so highly with our partners.**'
        },
    ]
}

export const hospitalityCards = {
    twoColumn: true,
    comparison: [
        {
            background: manchester ,
            overlayImage: manchester_overlay,
            subtitle: "Manchester United Hospitality",
            info: "Fancy a trip to Old Trafford? We’ve got seats in the Evolution Suite at Old Trafford, where we take our clients. So why not save your place, and register your interest?",
            label: "Learn More",
            url: "/manchester-united-hospitality/"
        },
        {
            background: everton,
            overlayImage: everton_overlay,
            subtitle: "Everton F.C. Hospitality",
            info: "Or even a trip to Goodison Park? We’ve got seats in the Brian Labone Lounge at Goodison – which is a fabulous day out. So why not save your place, and register your interest?",
            label: "Learn More",
            url: "/everton-fc-hospitality/"
        },
    ]
}

export const bannerCMS = {
    margin: true,
    backgroundColor: "#f9f9f9",
    themeColor: "#005D8F",
    questionMark: true,
    title: 'Would you like more information about our Claims Management Service?',
    description: 'If you have any questions about our Claims Management service, our team would be more than happy to answer your questions. Simply get in touch today.',
    displayBtn: true,
    label: "Get in Touch",
    link: "true",
    url: "/contact-us",
    bottomPadding: true
}

//hospitality subpages
export const headerMan = {
    image: webpMan,
    pngImage: pngMan,
    Aligned: true,
    Title: "Our Manchester United Hospitality:",
    Subtitle: [
        {
            text: 'FANCY COMING TO THE GAME?'
        },
        {
            text: 'WE’RE PAYING!'
        },
        {
            text: 'CAN WE COUNT YOU IN?'
        },
    ]
}

export const headerEvr = {
    image: webpEvr,
    pngImage: pngEvr,
    Aligned: true,
    Title: 'Our Everton Football Club Hospitality:',
    Subtitle: [
        {
            text: 'FANCY COMING TO THE GAME?'
        },
        {
            text: ' WE’RE PAYING!'
        },
        {
            text: 'CAN WE COUNT YOU IN?'
        },
    ],
}

export const bannerMan = {
    margin: true,
    backgroundColor: "#ffffff",
    themeColor: "#cb1f2d",
    questionMark: true,
    title: 'Would you like more information about our Manchester United Hospitality?',
    info: [
        {
            text: 'It would be great to catch up whilst enjoying good food and watching world class football.'
        },
        {
            text: 'With a real sense of Manchester United’s extraordinary achievements, it’s a superb location in which to be inspired by greatness.'
        },
        {
            text: 'We would love to welcome you on a match day, for you to experience United Legal Assistance’s passion!'
        },
        {
            text: 'Simply register your interest here!'
        }
    ],
    displayBtn: true,
    label: "Get in Touch",
    link: "true",
    url: "/",
}

export const bannerEvr = {
    margin: true,
    backgroundColor: "#ffffff",
    themeColor: "#063A99",
    questionMark: true,
    title: 'Would you like more information about our Everton Football Club Hospitality?',
    description: 'Enjoy match day in a relaxed atmosphere, then watch the game from outstanding directors box stadium seats.',
    info: [
        {
            text: '✓ Champagne arrival'
        },
        {
            text: '✓ 3-course meal'
        },
        {
            text: '✓ Free bar all day (wines, beers,spirits & soft drinks)'
        },
        {
            text: '✓ Half time tea & coffee'
        },
        {
            text: '✓ Match day programme'
        },
        {
            text: '✓ Guest speaker – usually a former player with the chance to meet and greet'
        },
        {
            text: '✓ Comfy seats in the Directors area of the main stand'
        },
        {
            text: 'HOW DOES THIS SOUND? REGISTER YOUR INTEREST TODAY!'
        },
    ],
    displayBtn: true,
    label: "Get in Touch",
    link: "true",
    url: "/",
}


//cold-call-warning page
export const coldCall = {
    title: "Public Alert - Cold Call Warning",
    text: [
        {
            info: "Members of the public have received telephone calls falsely claiming to be calling on behalf of “United Legal Solicitors” possibly from overseas.",
        },
        {
            info: "**United Legal Assistance Ltd** is not a Solicitors firm, and would not call out in this manner.",
        },
        {
            info: "**What is the call about?**",
        },
        {
            info: "The ICO has been informed that unsolicited telephone calls have been made to members of the public in the name of United Legal Solicitors or similar. It is reported the telephone calls have been made from various mobile telephone numbers and related to possible road traffic accident claims.",
        },
        {
            info: "Any business or transactions conducted through unsolicited telephone calls falsely claiming to be on behalf of **United Legal Assistance Ltd** in different names are not undertaken by a solicitor’s practice or an individual authorised or regulated by the SRA.",
        },
        {
            info: "United Legal Assistance Ltd confirm they have no connection with the telephone calls referred to above.",
        },
        {
            info: "For more information please visit: [ICO - Spam texts and nuisance sales calls](https://ico.org.uk/make-a-complaint/nuisance-calls-and-messages/spam-texts-and-nuisance-calls/)",
        },
    ]
}


//ogden-rate page
export const ogden = {
    text: [
        {
            info: `The Ogden rate is an important factor for any insurance broker to understand, as it affects how much claimants can expect to gain from a personal injury claim 
            and how much insurers will be charging for them to pursue it. A recent alteration in the law in regard to the Ogden rate is causing major ripples in the insurance 
            industry, but just what has changed and why does this make a difference?`,
        },
        {
            info: `As a broker, you’ll know that when a person makes a personal injury claim, they’re not only out for what they can get. More often than not they’re claiming 
            because that injury is about to affect their whole life – and that includes their finances. If the claimant can no longer work, any compensation they gain will need 
            to last them. This is where the Ogden rate – sometimes known as the discount rate – comes into play.`,
        },
        {
            info: `When a claimant with a life-changing injury accepts a lump sum insurance payment, this initial amount is adjusted depending on the interest they can expect 
            to earn by investing that money (thereby keeping them going for longer, supposedly covering loss of earnings and care costs). The Ogden rate is the amount that the lump 
            sum is adjusted. So basically, it’s a calculation used by the courts to work out how much the insurance companies should ultimately pay out.`,
        },
        {
            info: `Previously, the Ogden rate was set at 2.5% – so for every £1000 lost by the claimant, the insurer would pay out £975. It was expected that the claimant would then 
            be able to earn 2.5% interest a year by investing that money, thereby making up the rest of what they were due. In March 2017 however, the rate was slashed to -0.75. For 
            every £1000 lost then, the insurer will now have to pay out £1007.50.`,
        },
        {
            info: `So, what does this mean for the world of insurance? The change will cost the industry millions of pounds, and it’s not great for claimants either. Insurers are 
            having to pay out more, so they’ll be charging more. The cost of a personal injury claim is going to increase and insurers will also increase their reserves on existing 
            claims so that they can meet the awards made by the courts. As a result, the cost of motor, employers, public and products liability will be driven up.`,
        },
        {
            info: "If you have any questions or would like to discuss teh implications of the Ogden Rate please [Contact Us](/contact-us).",
        },
    ]
}


//blog page
export const posts = {
    title: 'Blog',
    contents: [
        {
            Question: 'Christmas 2020 – opening times',
            Answer: 'We would like to thank everyone for their support shown throughout this unprecedented year and wish everyone a very happy and safe Christmas. Instead of Xmas cards or gifts his year we are...',
            URL: 'christmas-2020-opening-times'
        },
        {
            Question: 'New Partner Announcement: Walmsleys Commercial Insurance Brokers',
            Answer: 'We are proud to announce that we have been appointed as claims management provider for one of the UK’s leading Chartered Insurance Brokers, Walmsleys Commercial. ULA will be providing a...',
            URL: 'new-partner-announcement-walmsleys-commercial-insurance-brokers'
        },
        {
            Question: '5 Technology Startups Disrupting the Insurance Industry',
            Answer: 'The rise of Insurance Technology has come as a surprise to those who view the industry of lacking forward thinking. However, in recent years the growth in FinTech and...',
            URL: '5-technology-startups-disrupting-insurance-industry'
        },
        {
            Question: '10 Tips for Under 25s Car Insurance',
            Answer: 'Finding affordable and comprehensive car insurance cover for young drivers is often a challenge, especially after the hard work of passing your test and saving...',
            URL: '10-tips-25s-car-insurance'
        },
        {
            Question: 'Meet the ULA Team: Pete Mitchell',
            Answer: 'Next up in our Meet the ULA team series is Pete Mitchell. Pete is our Operations Manager, and here is what he gets up to… Tell us about yourself and what you do...',
            URL: 'meet-ula-team-pete-mitchell'
        },
        {
            Question: 'The Things You Need to Know About Uninsured Loss Recovery!',
            Answer: 'We all know why we take out insurance, it’s a sensible thing to do and in many cases, also a legal requirement. We pay for our policy and can then sleep at...',
            URL: 'the-things-you-need-to-know-about-uninsured-loss-recovery'
        },
        {
            Question: 'Meet the ULA Team: Ben Navin',
            Answer: 'This is the first article in our new blog series; Meet the ULA Team. We wanted to give you an insight into the people behind United Legal, first up is our Claims...',
            URL: 'meet-ula-team-ben-navin'
        },
        {
            Question: 'What is Telematics Insurance, and what’s the advantages?',
            Answer: 'Today in the UK, there is over half-a-million motorists that have a telematics box installed in their vehicle, of which is part of a telematics insurance...',
            URL: 'what-is-telematics-insurance'
        },
        {
            Question: 'ULA Raise £600 for The Guide Dogs for the Blind Charity!',
            Answer: 'On Friday 12th August, our client DCJ Group organised a golf day for The Guide Dogs for the blind charity, which raised a total of £3,800. We decided to help...',
            URL: 'ula-raise-600-guide-dogs-blind-charity'
        },
        {
            Question: 'United Legal Assistance to Support Fraud Prevention',
            Answer: 'Personal Injury and motor fraud in the UK is a big problem. United Legal are pleased to be working with a market leading fraud prevention consultancy firm to...',
            URL: 'united-legal-assistance-support-fraud-prevention'
        },
        {
            Question: `Fully Comprehensive Insurance vs Third Party`,
            Answer: `When you drive, you have to have appropriate car insurance – the minimum cover being third party. Insurance companies offer various levels of cover and when you...`,
            URL: `fully-comprehensive-insurance-vs-third-party`
        },
        {
            Question: `Our Top 10 Reliable Cars of 2015`,
            Answer: `When you are thinking of buying a new car, the one thing you’re unlikely to compromise on is reliability and safety. It doesn’t matter if you’re buying a showy, sporty number or a good solid...`,
            URL: `top-10-reliable-cars-2015`
        },
        {
            Question: `What should I do after a car accident?`,
            Answer: `Being involved in a car accident, even just a bump, can be a stressful experience. In this blog, we’ll give you the information you need to help you decide what...`,
            URL: `what-should-i-do-after-a-car-accident`
        },
        {
            Question: `Welcome to United Legal Assistance`,
            Answer: `Welcome...`,
            URL: `welcome-united-legal-assistance`
        }
    ]
}